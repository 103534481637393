//
// Colors
//

// Override Bootstrap color variables

$yellow: #ffff00;
$cyan: #00ffff;
$blue: #0000ff;

// Override Bootstrap state colors variables

$primary: #006A6B;

// Override Bootstrap contrast ratio

$min-contrast-ratio: 1.5;

$navbar-brand: $primary;

$nav-link: #006A6B;
$nav-link-hover: cyan;

$bg-color: #006A6B;