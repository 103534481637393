//
// Styles
//

// Import variables
@import "./variables.scss";

// Import Bootstrap
@import "bootstrap/scss/bootstrap.scss";

// Global CSS
@import "./global.scss";

// Components
@import "./components/buttons.scss";
@import "./components/navbar.scss";

// Sections
@import "./sections/masthead.scss";
@import "./sections/portfolio.scss";
@import "./sections/timeline.scss";
@import "./sections/team.scss";
@import "./sections/contact.scss";
@import "./sections/footer.scss";
