//
// Team section styling
//

.team-member {
    margin-bottom: 3rem;
    text-align: center;

    img {
        width: 14rem;
        height: 14rem;
        border: 0.5rem solid fade-out($black, 0.9);
    }

    h4 {
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}

// Brand images for client section
.img-brand {
    height: 2.75rem;
}
