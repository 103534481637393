//
// Typography
//

// Override the default Bootstrap base font family
// 
// Roboto Slab is being used below and is brought in
// via Google fonts in the head of src/pug/index.pug.
// The fonts listed afterwards are fallbacks.

$font-family-base: "Roboto Slab",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";

// Override the default Bootstrap headings font family
// 
// Montserrat is being used below and is brought in
// via Google fonts in the head of src/pug/index.pug.
// The fonts listed afterwards are fallbacks.

$headings-font-family: "Montserrat",
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";

// Create a subheadings font family

$subheadings-font-family: $font-family-base;

// Override Bootstrap default headings variables

$headings-font-weight: 700;
$headings-letter-spacing: 0.0625em;

// Override default Bootstrap line height variables

$line-height-lg: 1.75;
